import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import { slickDot } from "../page-demo/script";
import { slideSlick } from "../page-demo/script";
import Scrollspy from "react-scrollspy";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import ServiceList from "../elements/service/ServiceList";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import Team from "../elements/Team";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import FooterTwo from "../component/footer/FooterTwo";
import Contact from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";
import PortfolioMasonry from "../elements/portfolio/PortfolioMasonry";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import AOS from "aos";

import { withTranslation } from "react-i18next";
import i18n from "i18next";

const SlideList = [
  {
    textPosition: "text-left",
    category: "",
    bgImage: "bg_image--2",
    //title: '新科建筑',

    description: "",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
];
class CreativeLanding extends Component {
  constructor(props) {
    super(props);
    this.state = { change: true };

    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });

    console.log(this);
  }

  componentWillMount = async () => {
    AOS.init({
      duration: 1000,
    });
  };
  
  render() {
    //20/05/2021
    const { t } = this.props;




    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 7000,
    };

    return (
      <Fragment>
        <Helmet pageTitle={t("navbar.1")} />
        {/* Start Header Area  */}
        

        {/* Start Slider Area   */}

        <div className="slider-wrapper">
          <div className="slider-activation  slider-creative-agency" id="home">
            <Slider
              className="rn-slick-dot dot-light"
              {...slideSlick}
              {...settings}
            >
              {SlideList.map((value, index) => (
                <div
                  className={`slide slide-style-2 d-flex align-items-center slider-paralax justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                  data-black-overlay="5"
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}

                          <h1 className="title">{t("title.1")}</h1>

                          <br />
                          <br />
                          {value.description ? (
                            <p className="description">{value.description}</p>
                          ) : (
                            ""
                          )}
                          {/* {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* <div className="slider-activation slider-creative-agency" id="home">
                    <div className="bg_image bg_image--33" data-black-overlay="6">
                        {SlideList.map((value, index) => (
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                                {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}

        {/* End Slider Area   */}
        {/* <div className="rn-portfolio-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h2 className="title">Our Service</h2>
                                     </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <PortfolioMasonry item="9" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" />
                        </div>
                    </div>
                </div> */}

        {/* Start Portfolio Area */}
        {/* <div className="portfolio-area ptb--120 bg_color--4">
                    <div className="portfolio-sacousel-inner mb--55">
                        <Portfolio />
                    </div>
                </div> */}
        {/* End Portfolio Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
                <br />
                <br />
                <h2 className="title" data-aos="zoom-in-left">
                  {t("Home.message")}
                </h2>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/about/zhuanye.jpg"
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h3 className="title" data-aos="zoom-in-left">
                      {t("Home.subTitle")}
                      </h3>
                      <br />
                      <br />
                      <br />
                      <p className="description" data-aos="zoom-in-left">
                      {t("Home.p1")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start Page Wrapper */}
        {/* <div className="rn-service-details ptb--120 bg_color--6" id="about2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        <div className="row sercice-details-content pb--80 align-items-center">

                                            <div className="col-lg-8 col-12">
                                                <div className="about-inner inner">
                                                    <div className="section-title">
                                                        <h3 className="title white-content">We deliver insightful designs.</h3>
                                                        <br /><br /><br />
                                                        <p className="description">Galaxy Asset is international credentials and intelligent design capabilities deliver integrated developments, designed with the future in mind.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/1.jpeg" alt="About Images" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* End Page Wrapper */}

        {/* Start Page Wrapper */}
        {/* <div className="rn-service-details ptb--120 bg_color--1" id="about3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-5 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/about/about-4.jpeg" alt="About Images" />
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-12">
                                                <div className="about-inner inner">
                                                    <div className="section-title">
                                                        <h3 className="title">We provide certainty of delivery.</h3>
                                                        <br /><br /><br />
                                                        <p className="description">Galaxy Asset’s experience and strong balance-sheet, enables us to finance, deliver and expedite the delivery of large-scale, high-quality, integrated developments.</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* End Page Wrapper */}

        {/* Start Page Wrapper */}
        {/* <div className="rn-service-details ptb--120 bg_color--4" id="about2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        <div className="row sercice-details-content pb--80 align-items-center">

                                            <div className="col-lg-6 col-12">
                                                <div className="about-inner inner">
                                                    <div className="section-title">
                                                        <h3 className="title white-content">We offer confidence.</h3>
                                                        <br /><br /><br />
                                                        <p className="description">Galaxy Asset aspires to provide investors and property owners with confidence in their investment decisions through our financial capacity and capability to deliver high-demand property product.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/about/about-5.jpeg" alt="About Images" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* End Page Wrapper */}

        {/* Start Service Area  */}
        {/* <div className="service-area creative-service-wrapper ptb--120 bg_color--1" id="service">
                    <div className="container">
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>   */}
        {/* End Service Area  */}

        {/* Start About Area */}
        {/* <div className="about-area ptb--120 bg_color--5" id="about">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">About Us</h2>
                                            <p className="description">There are many variations of passages of Lorem Ipsum available, but the majority have suffered <a href="#alteration">alteration</a> in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum,</p>
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who we are</h3>
                                                    <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who we are</h3>
                                                    <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* End About Area */}

        {/* Start Portfolio Area */}
        {/* <div className="portfolio-area pt--120 pb--140 bg_color--1" id="portfolio">
                    <div className="rn-slick-dot">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0">
                                        <h2 className="title">Our Portfolio</h2>
                                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slick-space-gutter--15 slickdot--20">
                                        <Slider {...slickDot}>
                                            {list.map((value , index) => (
                                                <div className="portfolio" key={index}>
                                                    <div className="thumbnail-inner">
                                                        <div className={`thumbnail ${value.image}`}></div>
                                                        <div className={`bg-blr-image ${value.image}`}></div>
                                                    </div>
                                                    <div className="content">
                                                        <div className="inner">
                                                            <p>{value.category}</p>
                                                            <h4><a href="/portfolio-details">{value.title}</a></h4>
                                                            <div className="portfolio-button">
                                                                <a className="rn-btn" href="/portfolio-details">Case Study</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> */}
        {/* End Portfolio Area */}

        {/* Start CounterUp Area */}
        {/* <div className="rn-counterup-area pt--140 p pb--110 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Our Fun Facts</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div> */}
        {/* End CounterUp Area */}

        {/* Start Team Area  */}
        {/* <div className="rn-team-area ptb--120 bg_color--1" id="team">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title service-style--3 text-left mb--25 mb_sm--0">
                                    <h2 className="title">Skilled Team</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <Team column="col-lg-4 col-md-6 col-sm-6 col-12" />
                        </div>
                    </div>
                </div> */}
        {/* End Team Area  */}

        {/* Start Testimonial Area */}
        {/* <div className="rn-testimonial-area bg_color--5 ptb--120" id="testimonial">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div> */}
        {/* End Testimonial Area */}

        {/* Start Blog Area */}
        {/* <div className="rn-blog-area pt--120 pb--140 bg_color--1" id="blog">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-6">
                                <div className="section-title text-left">
                                    <h2>Latest News</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--55 mt_sm--30 rn-slick-dot slick-space-gutter--15 slickdot--20 row--0">
                            <div className="col-lg-12">
                                <Slider {...slickDot}>
                                    {PostList.map((value , i ) => (
                                        <div className="blog blog-style--1" key={i}>
                                            <div className="thumbnail">
                                                <a href="/blog-details">
                                                    <img src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images"/>
                                                </a>
                                            </div>
                                            <div className="content">
                                                <p className="blogtype">{value.category}</p>
                                                <h4 className="title"><a href="/blog-details">{value.title}</a></h4>
                                                <div className="blog-btn">
                                                    <a className="rn-btn text-white" href="/blog-details">Read More</a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>    
                    </div>    
                </div> */}
        {/* End Blog Area */}

        {/* Start Contact Us */}
        {/* <div className="rn-contact-us ptb--120 bg_color--5" id="contact">
                    <Contact />
                </div> */}
        {/* End Contact Us */}

        {/* Start Brand Area */}
        {/* <div className="rn-brand-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* End Brand Area */}

        {/* Start Footer Style  */}
        {/* <FooterTwo id="home" /> */}
        {/* End Footer Style  */}
        {/* Start Back To Top */}
        {/* <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div> */}
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default withTranslation()(CreativeLanding);
