import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiMail, FiMapPin } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import FooterTwo from "../component/footer/FooterTwo";
import Scrollspy from "react-scrollspy";
import { FiPhoneCall } from "react-icons/fi";

import { withTranslation } from "react-i18next";

import AOS from "aos";
const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  };

  constructor(props) {
    super(props);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  componentWillMount = async () => {
    AOS.init({
      duration: 1000,
    });
  };
 
  openANewTab(){
    window.open("https://www.google.com.au/maps/place/408+King+William+St,+Adelaide+SA+5000/@-34.9345761,138.5985863,17z/data=!3m1!4b1!4m5!3m4!1s0x6ab0cedfb0f60743:0x2b5d3e66def8e783!8m2!3d-34.9345805!4d138.6007803")
  }

  copyText(text){ /* Select the text field */     
      
       
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(text);
    

  }


  render() {
    const { t } = this.props;

  

   
    return (
      <React.Fragment>
        <PageHelmet pageTitle={t("navbar.4")} />

        
        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--160 pb--150 bg_image bg_image--9"
          data-black-overlay="5"
          id="contact"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-left pt--100">
                  <h2 className="title">{t("title.4")}</h2>
                  {/* <p>Contrary to popular belief, Lorem Ipsum is not simply random text. </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Contact Top Area  */}
        <div className="rn-contact-top-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              {/* Start Single Address  */}
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="rn-address">
                  <div className="icon" onClick={()=>{this.copyText("+61 0426700961")}} >
       <FiPhoneCall />
                             <span class="tooltiptext" id="myTooltip">Click to copy the phone number</span></div>
                    
                  
                  <div className="inner">
                    <h4 className="title">{t("Contact.phone")}</h4>
                    <p>
                      <a href="tel:+61 870706160">Tel: +61 (08) 70706160</a>
                    </p>
                     <p>
                      <a href="tel:+61 0492920382">Mob: +61 0426700961</a>
                    </p>
               
               
                  
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--50">
                <div className="rn-address">
                  <div className="icon" onClick={()=>{this.copyText("info@nusunm.com")}}>
                    <FiMail />
                       <span class="tooltiptext" id="myTooltip">Click to copy the address</span>
                    
                  </div>
                  <div className="inner">
                    <h4 className="title">{t("Contact.email")}</h4>
                    <p>
                      <a href="mailto:info@nusunm.com" >info@nusunm.com</a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt_md--50 mt_sm--50">
                <div className="rn-address">
                  <div className="icon"  onClick={this.openANewTab}>
             

                    <FiMapPin/>
                     <span class="tooltiptext" id="myTooltip">Click to go to the Google Map</span>
                  </div>
                  <div className="inner">
                    <h4 className="title">{t("Contact.address")}</h4>
                    <p>408 King William St, Adelaide SA 5000</p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}

        {/* Start Contact Page Area  */}
        {/* <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactTwo />
                </div> */}
        {/* End Contact Page Area  */}
        {/* <FooterTwo id="contact" /> */}
        {/* Start Back To Top */}
        {/* <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div> */}
        {/* End Back To Top */}
      </React.Fragment>
    );
  }
}
export default withTranslation()(Contact);
