import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Scrollspy from "react-scrollspy";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import FooterTwo from "../component/footer/FooterTwo";
import AOS from "aos";

const PortfolioList = [
  {
    images: "01",
    category: "Freelancer",
    title: "The Language of Developer",
  },
  {
    images: "02",
    category: "Freelancer",
    title: "The new Thinking for Design",
  },
  {
    images: "03",
    category: "Freelancer",
    title: "The new Thinking for Design",
  },
  {
    images: "04",
    category: "Freelancer",
    title: "Getting tickets to the big show",
  },
  {
    images: "08",
    category: "Freelancer",
    title: "You can see your Portfolio",
  },
];

const TabOne = [
  {
    image: "1",
    bigImage: "/assets/images/project/project-1.jpg",
    category: "分成4个块地，均为两层 (720 sqm)",
    title: "11 Newman street， Magill",
  },
  {
    image: "2",
    bigImage: "/assets/images/project/project-2.jpg",
    category: "分成3个块地，均为两层 (800 sqm)",
    title: "54 Jervois street south， Plympton",
  },
  {
    image: "3",
    bigImage: "/assets/images/project/project-3.jpg",
    category: "分成3个块地，均为两层 (792 sqm)",
    title: "527 Marion road South， Plympton",
  },
  {
    image: "4",
    bigImage: "/assets/images/project/project-4.jpg",
    category: "分成4个块地，均为两层 (795 sqm)",
    title: "25 Faehse street， Modbury",
  },
];

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab1: 0,
      isOpen: false,
    };

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  openModal = () => {
    this.setState({ isOpen: true });
  };
  componentWillMount = async () => {
    AOS.init({
      duration: 1000,
    });
  };



  render() {
    const { t } = this.props;

  
    const { tab1, isOpen } = this.state;

    return (
      <div>
        <PageHelmet pageTitle={t("navbar.3")} />

        
        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--160 pb--150 bg_image bg_image--15"
          data-black-overlay="5"
          id="projects"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-left pt--100">
                  <h2 className="title">{t("title.3")}</h2>
                  {/* <p>Contrary to popular belief, Lorem Ipsum is not simply random text. </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper  */}
        <main className="page-wrapper">
          {/* Start Portfolio Area  */}
          <div className="rn-portfolio-area ptb--120 bg_color--1 line-separator">
            <div className="container">
              <div className="row">
                {TabOne.map((value, index) => (
                  <div className="col-lg-6" key={index}>
                    {isOpen && (
                      <Lightbox
                        mainSrc={TabOne[tab1].bigImage}
                        nextSrc={TabOne[(tab1 + 1) % TabOne.length]}
                        prevSrc={
                          TabOne[(tab1 + TabOne.length - 1) % TabOne.length]
                        }
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                          this.setState({
                            tab1: (tab1 + TabOne.length - 1) % TabOne.length,
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            tab1: (tab1 + 1) % TabOne.length,
                          })
                        }
                      />
                    )}
                    <div className="item-portfolio-static">
                      <div
                        onClick={() =>
                          this.setState({ isOpen: true, tab1: index })
                        }
                      >
                        <div className="portfolio-static">
                          <div className="thumbnail-inner">
                            <div className="thumbnail">
                              <a>
                                <img
                                  src={value.bigImage}
                                  alt="Portfolio Images"
                                />
                              </a>
                            </div>
                          </div>
                          <div className="content">
                            <div className="inner">
                              <p>{value.category}</p>
                              <h4>
                                <a>{value.title}</a>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* End Portfolio Area  */}

          {/* Start Portfolio Area  */}
          {/*<div className="creative-portfolio-wrapper ptb--120 bg_color--1">
                    <div className="container plr--10">
                        <div className="row row--5">
                            {PortfolioList.map((value , i ) => (
                                <div className="col-lg-4 col-md-6 col-12" key={i}>
                                    <div className="portfolio-style--3">
                                        <div className="thumbnail">
                                            <a href="/portfolio-details">
                                                <img className="w-100" src={`/assets/images/portfolio/portfolio-static-${value.images}.jpg`} alt="Portfolio Images"/>
                                            </a>
                                        </div>
                                        <div className="content">
                                            <p className="portfoliotype">{value.category}</p>
                                            <h4 className="title"><a href="/portfolio-details">{value.title}</a></h4>
                                            <div className="portfolio-btn">
                                                <a className="rn-btn text-white" href="/portfolio-details">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>*/}
          {/* End Portfolio Area  */}
        </main>
        {/* End Page Wrapper  */}

        {/* Start Footer Area  */}
        {/* <FooterTwo id="project" /> */}
        {/* End Footer Area  */}
        {/* Start Back To Top */}
        {/* <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div> */}
        {/* End Back To Top */}
      </div>
    );
  }
}

export default withTranslation()(Gallery);
