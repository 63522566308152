import React, { Component } from "react";
import { browserHistory } from "react-router";

const shareNews = [
  {
    image: "image-1",
    link: "https://mp.weixin.qq.com/s/kJDelTVIShvNROzTxl_g9Q",
    category: "住宅项目简介",
    title: "Prospects SA",
  },
  {
    image: "image-2",
    link: "https://mp.weixin.qq.com/s/ihMDoF5zLUyb08b5vdjyRQ",
    category: "墙面",
    title: "Colorbond的介绍",
  },
  {
    image: "image-3",
    link: "https://mp.weixin.qq.com/s/ldG7UleqbDq05Q2RDyl80A",
    category: "墙面",
    title: "建筑材料简介",
  },
  {
    image: "image-4",
    link: "https://mp.weixin.qq.com/s/PoZXdolAGNPeHZIfu3FmFg",
    category: "墙面",
    title: "涂料的选择",
  },
];

class PortfolioMasonry extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { column } = this.props;
    const list = shareNews.slice(0, this.props.item);
    return (
      <React.Fragment>
        {list.map((value, index) => (
          <div className={`${column}`} key={index}>
              <a
                      href={`${value.link}`}
                      rel="noopener noreferrer tag"
                      target="_blank"
                    >
            <div className="Tilt-inner">
              <div className="portfolio">
                <div className="thumbnail-inner">
                  <div className={`thumbnail ${value.image}`}></div>
                  {/* <div className={`bg-blr-image ${value.image}`}></div>*/}
                </div>
                <div className="content">
                  <div className="inner">
                    <p>{value.category}</p>
                    <h4>{value.title}</h4>
                  
                      <button type="button" className="newsB">
                        Read More
                      </button>
                  
                  </div>
                </div>
              </div>
            </div>
            </a>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default PortfolioMasonry;
