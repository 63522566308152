import React from "react";
import {
  FaWeixin,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";

const SocialShare = [
  {
    Social: <FaWeixin />,
    link: "https://mp.weixin.qq.com/s/Eojbjw-utOBVjhU4GVvCYw",
  },
  // { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
  // { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
  // { Social: <FaTwitter />, link: 'https://twitter.com/' },
];

const FooterTwo = () => {
  AOS.init({
    duration: 1000,
  });
  return (
    <div
      className="footer-style-2 ptb--30 bg_image bg_image--1"
      data-black-overlay="5"
    >
      <div className="wrapper plr--50 plr_sm--20">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="inner">
              <div className="logo text-center text-sm-left mb_sm--20">
                <a href="/">
                  <img
                    style={{ width: "110px" }}
                    data-aos="zoom-in-right"
                    data-aos-duration="1500"
                    src="/assets/images/logo.png"
                    alt="Nusunm"
                  />
                </a>
              </div>
            </div>
            <div className="inner text-lg-left text-center mt_md--20 mt_sm--20">
              <div className="text">
                <br />
                <br />
                <p
                  data-aos="zoom-in-right"
                  data-aos-duration="1500"
                  data-aos-delay="400"
                >
                  Copyright © 2021 Nusunm Group.
                </p>
                <p
                  data-aos="zoom-in-right"
                  data-aos-duration="1500"
                  data-aos-delay="800"
                >
                  All Rights Reserved.
                </p>
                <br />
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner text-center">
                            <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                {SocialShare.map((val , i) => (
                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div> */}
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="inner text-lg-left text-center mt_md--20 mt_sm--20">
              <div className="text">
                <p
                  data-aos="zoom-in-left"
                  data-aos-duration="1500"
                  data-aos-delay="1200"
                >
                  83 Payneham Rd, St Peters, SA 5069
                </p>
                <p
                  data-aos="zoom-in-left"
                  data-aos-duration="1500"
                  data-aos-delay="1600"
                >
                  info@nusunm.com
                </p>
                <p
                  data-aos="zoom-in-left"
                  data-aos-duration="1500"
                  data-aos-delay="2000"
                >
                  Tel: 08-7070-6160
                </p>
                <br />
              </div>
            </div>
            <div className="inner text-center">
              <ul
                className="social-share rn-lg-size d-flex liststyle"
                data-aos="zoom-in-up"
                data-aos-duration="1500"
                data-aos-delay="2400"
              >
                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a href={`${val.link}`}>{val.Social}</a>
                    <br />
                    <br />
                    <br />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FooterTwo;
