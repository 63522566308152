import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";


const PortfolioList = [
    {
        image: 'image-1',
        category: 'Development',
        title: 'Comprehensive development management'
    },
    {
        image: 'image-2',
        category: 'Development',
        title: 'Due Diligence for capital investors contemplating property joint ventures with developers or others'
    },
    {
        image: 'image-3',
        category: 'Development',
        title: 'Assisting Architects and Town Planners with research for clients unfamiliar with local market conditions in determining appropriate product mix, sizing and price points for residential projects'
    },
    {
        image: 'image-4',
        category: 'Development',
        title: 'Locating development sites for offshore developers – with feasibility work'
    },
    {
        image: 'image-5',
        category: 'Development',
        title: 'Development management advice and strategies for landowners not familiar with the development process – strategies for development permits and enhancing property values'
    },
    {
        image: 'image-6',
        category: 'Development',
        title: 'Assisting landowners undertake development schemes and market intelligence to package the sale of their property based on potential development yield not just market value'
    },
    {
        image: 'image-7',
        category: 'Development',
        title: 'Assisting landowners acquire adjoining sites to expand their residential unit / hotel business by undertaking yield and feasibility studies'
    }
]

class Portfolio extends Component{
    render(){

        return(
            <React.Fragment>
                <div className="portfolio-wrapper">

                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {PortfolioList.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">

                                            <p>{value.title}</p>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;