import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import { slickDot } from "../page-demo/script";
import Scrollspy from "react-scrollspy";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import ServiceList from "../elements/service/ServiceList";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import Team from "../elements/Team";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import FooterTwo from "../component/footer/FooterTwo";
import Contact from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";
import PortfolioMasonry from "../elements/portfolio/PortfolioMasonry";
import ModalVideo from "react-modal-video";
import AOS from "aos";
import { withTranslation } from "react-i18next";
import i18n from "i18next";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  openModal = () => {
    this.setState({ isOpen: true });
  };
  componentWillMount = async () => {
    AOS.init({
      duration: 1000,
    });
  };


  render() {
    const { t } = this.props;

   
    return (
      <Fragment>
        <Helmet pageTitle={t("navbar.2")} />
        

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--160 pb--150 bg_image bg_image--34"
          data-black-overlay="5"
          id="about"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-left pt--100">
                  <h2 className="title ">{t("title.2")}</h2>
                  {/* <p>Contrary to popular belief, Lorem Ipsum is not simply random text. </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1" id="about2">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="about-inner inner">
                          <div className="section-title">
                            <h3 className="title" data-aos="zoom-in-left">
                              {t("About.top.title")}
                            </h3>
                            <br />
                            <br />
                            <br />
                            <p className="description" data-aos="zoom-in-left">
                            {t("About.p1")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src="/assets/images/service/5.jpg"
                            alt="About us"
                            data-aos="zoom-in-left"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src="/assets/images/service/6.jpg"
                            alt="About us"
                            data-aos="zoom-in-left"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="about-inner inner">
                          <div className="section-title">
                            <p className="description" data-aos="zoom-in-left">
                            {t("About.p2")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Footer Style  */}
        
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        {/* <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div> */}
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default withTranslation()(About);
