import React from 'react';
import Scrollspy from "react-scrollspy";
import { FiX, FiMenu } from "react-icons/fi";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
class HeaderSix extends React.Component {
    menuTrigger = () => {
        document.querySelector(".header-wrapper").classList.toggle("menu-open");
      };
      CLoseMenuTrigger = () => {
        document.querySelector(".header-wrapper").classList.remove("menu-open");
      };

    
      changelanguage = () => {
        console.log(this.props.i18n.language);
        if (this.props.i18n.language == "en") {
          i18n.changeLanguage("cn");
        } else if (this.props.i18n.language == "cn") {
          i18n.changeLanguage("en");
        }
      };
      
    render (
        
    ){
        window.addEventListener("scroll", function () {
            var value = window.scrollY;
            if (value > 100) {
              document.querySelector(".header--fixed").classList.add("sticky");
            } else {
              document.querySelector(".header--fixed").classList.remove("sticky");
            }
          });
      
        const { t } = this.props;

        var elements = document.querySelectorAll(".has-droupdown > a");
        for (var i in elements) {
          if (elements.hasOwnProperty(i)) {
            elements[i].onclick = function () {
              this.parentElement
                .querySelector(".submenu")
                .classList.toggle("active");
              this.classList.toggle("open");
            };
          }
        }

        return (<>
        <header className="header-area formobile-menu header--fixed default-color">
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/">
                <img
                  className="logo-1"
                  src="/assets/images/logo.png"
                  alt="Nusunm"
                />
                <img
                  className="logo-2"
                  src="/assets/images/logoD.png"
                  alt="Nusunm"
                  
                />
              </a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <Scrollspy
                className="mainmenu"
                items={[
                  "home",
                  "about",
                  "projects",
                  "news",
                  "contact",
                  "Chinese/English",
                ]}
                currentClassName="is-current"
                offset={-200}
              >
                <li>
                  <a href="/home">{t("navbar.1")}</a>
                </li>
                <li>
                  <a href="/about">{t("navbar.2")}</a>
                </li>
                <li>
                  <a href="/project">{t("navbar.3")}</a>
                </li>
                <li>
                  <a href="/news">{t("navbar.5")}</a>
                </li>
                <li>
                  <a href="/contact">{t("navbar.4")}</a>
                </li>

                {/*change language button*/}
                <li>
                  <a type="button" onClick={this.changelanguage}>
                    {t("language")}
                  </a>
                </li>
                <li>
                  <a
                    href="http://design.nusunm.com/"
                    style={{ fontStyle: "italic" }}
                  >
                    Nusunm Design
                  </a>
                </li>
              </Scrollspy>
            </nav>

            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
      {/* End Header Area  */}  
      </>)
    }
}

export default  withTranslation()(HeaderSix)
